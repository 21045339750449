<template>
  <section class="invoice-preview-wrapper">

    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        md="8"
        xl="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->

          <!-- Spacer -->
          <!-- <hr class="invoice-spacing"> -->

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                
                <h4 class="mb-25">
                  {{ $t('apps.transactions.purchase.singular.receiveFrom')}}
                </h4>
                <h6 class="card-text mb-25">
                  {{ invoice.contact.code }} - {{ invoice.contact.brand }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoice.contact.billing_address }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.contact.brand_phone }}
                </p>
                <p class="card-text mb-0">
                  {{ invoice.contact.brand_email }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.company.address }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h4 class="mb-2">
                    <!-- {{ $t('apps.transactions.sales.singular.paymentInstruction')}}: -->                    
                    {{ $t('apps.transactions.purchase.invoice.singular.invoice')}} : 
                    <span class="invoice-number small text-primary"><b>{{ invoice.invoice_no }}</b></span>
                  </h4>
                  <h3>
                    {{ invoice.code }}
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          {{ $t('apps.transactions.purchase.invoice.singular.invoiceDate')}}
                        </td>
                        <td class="text-left">: {{ invoice.date }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ $t('apps.transactions.purchase.invoice.singular.dueDate')}}
                        </td>
                        <td class="text-left">: {{ invoice.due_date }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ $t('globalSingular.bankName')}}
                        </td>
                        <td class="text-left">: {{ bankName }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ $t('globalSingular.bankNumber')}}
                        </td>
                        <td class="text-left">: {{ accountBank }}</td>
                      </tr>
                      <!-- <tr>
                        <td class="pr-1">
                          Total Due
                        </td>
                        <td class="text-right"><span class="font-weight-bold">Rp. 25.000.000,00 </span></td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoice.details"
            :fields="tableColumns"
          >
            <template #cell(qty)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ parseFloat(data.item.quantity) }}
              </b-card-text>
            </template>

            <template #cell(itemLabel)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.product.name }}
              </b-card-text>
              <b-card-text>
                {{ data.item.remark }}
              </b-card-text>
            </template>

            <template #cell(tax)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.tax.name }}
              </b-card-text>
            </template>

            <template #cell(unitPrice)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ `${formatCurrency(parseFloat(data.item.amount))}` }}
              </b-card-text>
            </template>

            <template #cell(disc)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ `${formatCurrency(parseFloat(data.item.disc_amount))}` }}
              </b-card-text>
            </template>

            <template #cell(total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ `${formatCurrency(parseFloat(data.item.base_amount))}` }}
              </b-card-text>
            </template>

            <template v-slot:custom-foot="{}">
              <b-tr>
                <b-td :colspan="tfootColSpan">&nbsp;</b-td>
                <b-td class="pl-5 py-1">
                  <div class="h5 mb-0 font-weight-bolder">{{ $t('globalSingular.subTotal') }}</div>
                </b-td>
                <b-td class="text-right">
                  <div class="h5 mb-0 font-weight-bolder">{{ formatCurrency(parseFloat(invoice.sum_of_base_amount)) }}</div>
                </b-td>
              </b-tr>
              <b-tr v-if="parseFloat(invoice.sum_of_disc_amount) > 0">
                <b-td :colspan="tfootColSpan" class="border-0">&nbsp;</b-td>
                <b-td class="border-0 pb-1 pl-5">
                  <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.discount') }}</div>
                </b-td>
                <b-td class="border-0 text-right align-baseline">
                  <div class="h6 mb-0 font-weight-bolder text-danger">{{ formatCurrency(parseFloat(invoice.sum_of_disc_amount)) }}</div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td :colspan="tfootColSpan" class="border-0">&nbsp;</b-td>
                <b-td class="border-0 pb-1 pl-5">
                  <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.taxBase') }}</div>
                </b-td>
                <b-td class="border-0 text-right align-baseline">
                  <div class="h6 mb-0 font-weight-bolder">{{ formatCurrency(parseFloat(invoice.sum_of_tax_base_amount)) }}</div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td :colspan="tfootColSpan" class="border-0">&nbsp;</b-td>
                <b-td class="border-0 pb-1 pl-5">
                  <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.tax') }}</div>
                </b-td>
                <b-td class="border-0 text-right align-baseline">
                  <div class="h6 mb-0 font-weight-bolder">{{ formatCurrency(parseFloat(invoice.sum_of_tax_amount)) }}</div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td :colspan="tfootColSpan" class="border-0">&nbsp;</b-td>
                <b-td colspan="2" class="border-0 pl-5 py-0">
                  <hr>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td :colspan="tfootColSpan" class="border-0">
                  &nbsp;
                </b-td>
                <b-td class="border-0 align-baseline pl-5">
                  <div class="h4 mb-0 font-weight-bolder">{{ $t('globalSingular.total') }}</div>
                </b-td>
                <b-td class="border-0 text-right align-baseline pl-4">
                  <div class="h4 mb-0 font-weight-bolder">{{ formatCurrency(parseFloat(invoice.sum_of_bill_amount)) }}</div>
                </b-td>
              </b-tr>
            </template>

          </b-table-lite>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>{{ invoice.remark }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="4"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <!-- <b-button
            class="mb-75"
            variant="outline-secondary"
            block
            @click="printInvoice"
          >
            <feather-icon
              icon="EyeIcon"
            />
            {{ $t('globalActions.prints') }}
          </b-button> -->

          <!-- Button: Edit -->
          <b-button
            class="mb-75"
            variant="outline-secondary"
            block
            :to="{ name: 'apps-transactions-purchase-invoice-detail', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="Edit2Icon"
            />
            Edit
          </b-button>

          <!-- Button: Delete -->
          <b-button
            class="mb-75"
            variant="danger"
            block
            @click="handleDelete"
          >
            <feather-icon
              icon="Trash2Icon"
            />
            {{ $t('globalActions.delete') }}
          </b-button>

          <!-- Button: Approve Invoice -->
          <b-button
            v-if="!invoice.is_invoiced"
            class="mb-75"
            variant="success"
            block
            @click="handleDirectInvoice"
          >
            <feather-icon
              icon="CheckIcon"
            />
            {{ $t('globalActions.approveToInvoice') }}
          </b-button>

          <b-button
            v-if="invoice.is_invoiced && (invoice.form_payment_id == '' || invoice.form_payment_id == null) "
            v-ripple.400="'rgba(119, 175, 139, 0.15)'"
            variant="outline-info"
            block
            @click="printFormPaymet(paramsId)"
          >
          <feather-icon
               icon="PrinterIcon"
               class="mr-50"
             />
            {{ $t('globalActions.createForm') }}
          </b-button>

          <!-- Button: Add Payment -->
          <!-- <b-button
            v-if="invoice.is_invoiced && parseFloat(invoice.outstanding_amount) > 0"
            class="mb-75"
            variant="success"
            block
            :to="{name: 'apps-transactions-sales-payment-add-per-invoice', params: { id: paramsId }}"
          >
            <feather-icon
              icon="CreditCardIcon"
            />
            Receive Payment
          </b-button> -->
        </b-card>

        <b-card title="Payments">
          <div class="mb-1">
            <span class="h6 font-weight-bolder text-success">{{ formatCurrency(parseFloat(invoice.receipt_payment_amount)) }} </span>
            <span class="h4 font-weight-bolder text-secondary">/ {{ formatCurrency(parseFloat(invoice.sum_of_bill_amount)) }}</span>
          </div>
          <b-progress
            height="1.1rem"
            class="mb-2"
            :max="parseFloat(invoice.sum_of_bill_amount)"
          >
            <b-progress-bar
              variant="success"
              :value="parseFloat(invoice.receipt_payment_amount)">
              <strong>{{ (parseFloat(invoice.receipt_payment_amount) / parseFloat(invoice.sum_of_bill_amount) * 100).toFixed() }}%</strong>
            </b-progress-bar>
          </b-progress>
          <app-timeline>
            <app-timeline-item
              icon="CreditCardIcon"
              variant="success"
              v-for="payment in invoice.payments"
              :key="payment.id"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ payment.master.code }}</h6>
                <small class="text-muted">{{ payment.master.date }}</small>
              </div>
              <p>{{ payment.master.description }}</p>
              <div class="h6 mb-0 font-weight-bolder text-success">
                + {{formatCurrency(parseFloat(payment.amount))}}
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-card>
        <div v-if="dataTable.length != 0">
           <b-card
              class="card-transaction"
              no-body
            >
               <b-card-header>
                 <b-card-title>Documents</b-card-title>
                 <feather-icon
                   icon="MoreVerticalIcon"
                   size="18"
                   class="cursor-pointer"
                 />
               </b-card-header>
               <b-card-body>
                 <div
                   v-for="(detailsImage) in dataTable"
                   :key="detailsImage"
                   class="transaction-item"
                 >
                 <b-link
                  @click="newTab(detailsImage.image)"
                  class="font-weight-bolder"
                >
                   <b-media no-body>
                       <b-media-aside>
                         <b-avatar
                         rounded
                         size="42"
                         variant="light-primary"
                        >
                         <feather-icon
                           size="18"
                           icon="FileTextIcon"
                         />
                         </b-avatar>
                       </b-media-aside>
                      <b-media-body>
                       <h6 class="transaction-title">
                         <feather-icon
                             icon="EyeIcon"
                           />
                       </h6>
                       <small>{{ detailsImage.image }}</small>
                     </b-media-body>
                   </b-media>
                  </b-link>
                 </div>
               </b-card-body>
           </b-card>
        </div>
      </b-col>
    </b-row>
     <div class="mt-2 d-flex flex-sm-column flex-md-row justify-content-between">
        <b-button
              class="mb-sm-1 mb-md-0"
              :variant="'outline-secondary'"
              @click="() => handleBack()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
              />
              {{ $t('globalActions.backToList') }}
            </b-button>
      </div>
  </section>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BTr,
  BTd,
  BCardText,
  BButton,
  BProgress,
  BProgressBar,
  BCardHeader,
  BCardTitle,
  /* BAlert,
  BLink,*/
  VBToggle,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink
}  from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useHttp from '@/comp-functions/useHttp'
import { formatCurrency } from '@/utils/formatter'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  directives: {
    // Ripple,
    'b-toggle': VBToggle
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BTr,
    BTd,
    BCardText,
    BButton,
    BProgress,
    BProgressBar,
    AppTimeline,
    AppTimelineItem,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BLink
  },
  setup () {
    const { $get, $post, $delete } = useHttp()
    // const { $t } = useLocalization()
    const paramsId = router.currentRoute.params.id
    const thClassDisc = ref('text-right')
    const tdClassDisc = ref('text-right')
    const tfootColSpan = ref(5)

    const tableColumns = ref([])
    const paymentDetails = ref({})
    const invoice = ref({
      company: {
        name: ''
      },
      contact: {
        pic_name: ''
      },
      payments: [
        {
          master: {
            code: '',
            description: ''
          }
        }
      ]
    })

    const hideDiscountColumn = () => {
      // check if discount is zero, then hide column discount
      if (parseFloat(invoice.value.sum_of_disc_amount) === 0) {
        thClassDisc.value = thClassDisc.value.concat(' d-none')
        tdClassDisc.value = tdClassDisc.value.concat(' d-none')
        tfootColSpan.value = 4
      }
    }
    let dataTable   = [];
    let bankName    = ref()
    let accountBank = ref()
    const getDataPreview = async () => {
      
      const { data } =  await $get({
        url: `transaction/purchase/invoice/${paramsId}`
      })

        for (let i = 0; i < data.name_file.length; i++) {
            dataTable.push({
                code: data.code,
                image: data.name_file[i],
                id: data.id
            })
      }

      for (let index = 0; index < data.contact.bank_information.length; index++) {
        if(data.contact.bank_information[index].isContact){
          bankName.value = data.contact.bank_information[index].bankName
          accountBank.value = data.contact.bank_information[index].bankAccountNumber
        }
      }

      if (accountBank.value == undefined){
          accountBank.value = '-'
      }

      if (bankName.value == undefined){
          bankName.value = '-'
      }

      invoice.value  = data
      hideDiscountColumn()
    }

    const printInvoice = () => {
      window.print()
    }

    onMounted(async () => {
      await getDataPreview()
      tableColumns.value = [
        { key: 'itemLabel', label: 'Item Description' },
        { key: 'qty', label: 'Qty' },
        { key: 'disc', label: 'DISC (%)', thClass: 'text-right', tdClass: 'text-right' },
        { key: 'unitPrice', label: 'Price', thClass: 'text-right', tdClass: 'text-right' },
        { key: 'tax', label: 'Tax', thClass: 'text-right', tdClass: 'text-right' },
        { key: 'disc', label: 'Discount', thClass: thClassDisc.value, tdClass: tdClassDisc.value },
        { key: 'total', label: 'Total', thClass: 'text-right', tdClass: 'text-right' }
      ]

    })

      const printFormPaymet = async(id) =>{
      const  result  =  await $get({url: `transaction/purchase/payment-voucher/createForm/${id}`})
      router.push({ name: 'apps-transactions-form-payment-vendor-report', params: { id: result.data.id } })
    }

     const newTab = async (image) => {
        var link = process.env.VUE_APP_BASE_API_URL
        link = link.replace('/api','');
        window.open(link + 'assets/images/purchaseInvoice/'+ image)
    }

    return {
      printFormPaymet,
      bankName,
      accountBank,
      newTab,
      dataTable,
      paramsId,
      formatCurrency,
      tableColumns,
      invoice,
      tfootColSpan,
      paymentDetails,
      printInvoice,
      delete: $delete,
      post: $post
    }
  },

  methods: {
    handleDelete () {
      this.$swal({
        title: this.$t('apps.transactions.purchase.invoice.confirmations.delete'),
        text: this.$t('apps.transactions.purchase.invoice.confirmations.subTitleDelete'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value}) => {
          if (value) {
            this.delete({
              url: `transaction/purchase/invoice/${this.$router.currentRoute.params.id}`
            }).then(() => {
              this.$router.replace({ name: 'apps-transactions-purchase-invoice-list' })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${this.$t('feedback.success')} ${this.$t('apps.transactions.purchase.invoice.singular.deleteInvoice')}`
                }
              })
            })
          }
        })
    },

    async handleDirectInvoice () {
      this.$swal({
        title: this.$t('apps.transactions.purchase.invoice.confirmations.approve'),
        text: this.$t('apps.transactions.purchase.invoice.confirmations.subTitleApprove'),
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value}) => {
          if (value) {
            this.post({
              url: `transaction/purchase/invoice/${this.$router.currentRoute.params.id}`
            }).then(() => {
              this.$router.replace({ name: 'apps-transactions-purchase-invoice-list' })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${this.$t('feedback.success')} ${this.$t('apps.transactions.purchase.invoice.singular.approveInvoice')}`
                }
              })
            })
          }
        })
    },

    handleBack() {
      const beforeName = `${this.$router.currentRoute.name}`
      this.$router.back()

      setTimeout(() => {
        const afterName = `${this.$router.currentRoute.name}`
        if (beforeName === afterName) {
          if (afterName.includes('bacc')) {
            return this.$router.replace({ name: 'apps-transactions-bacc-transaction-list' })
          } else {
            return this.$router.replace({ name: 'apps-transactions-purchase-invoice-list' })
          }
        }
      }, 50);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .content-header {
    display: none !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
